/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/style.css';
import './styles/media.css';
import './styles/header.css';
import './styles/general.css';
import './styles/foot.css';
import './styles/font.css';
import './styles/general.css';
import './styles/nologin/header-nologin.css';
import './styles/nologin/style-nologin.css';

import 'bootstrap/dist/css/bootstrap.min.css'
// start the Stimulus application
//import './bootstrap';
var $ = require('jquery');

global.$ = global.jQuery = $;

require('bootstrap');